var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-auth',[_c('div',{staticClass:"px-138"},[_c('div',{staticClass:"my-96"},[_c('img',{attrs:{"src":require("@/assets/images/logo/dashboard-logo.svg"),"alt":""}})]),_c('app-heading-1',{},[_vm._v("Welcome to Collabdocs")]),_c('app-normal-text',{staticClass:"mb-2"},[_vm._v("Please update following information before proceeding next")]),_c('b-alert',{staticClass:"p-1",attrs:{"variant":"danger","show":_vm.errorMessage.length}},[_vm._v(_vm._s(_vm.errorMessage))]),_c('validation-observer',{ref:"registerForm"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"name":"First name","vid":"first_name","rules":"required|name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"label":"First Name","placeholder":"Jhon","errors":errors},on:{"onKeydown":_vm.isLetter},model:{value:(_vm.user_data.first_name),callback:function ($$v) {_vm.$set(_vm.user_data, "first_name", $$v)},expression:"user_data.first_name"}})]}}])})],1),_c('div',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"name":"Last name","vid":"last_name","rules":"required|name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"label":"Last Name","placeholder":"Doe","errors":errors},on:{"onKeydown":_vm.isLetter},model:{value:(_vm.user_data.last_name),callback:function ($$v) {_vm.$set(_vm.user_data, "last_name", $$v)},expression:"user_data.last_name"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Phone number","vid":"phone_number","rules":"required|phoneno"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-text-input',{attrs:{"label":"Phone Number","placeholder":"xxx xxx xxxx","errors":errors},on:{"onKeydown":_vm.isPhoneNumber},model:{value:(_vm.user_data.phone_number),callback:function ($$v) {_vm.$set(_vm.user_data, "phone_number", $$v)},expression:"user_data.phone_number"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|validPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-password-input',{attrs:{"label":"Password","placeholder":"Enter password","errors":errors},model:{value:(_vm.user_data.password),callback:function ($$v) {_vm.$set(_vm.user_data, "password", $$v)},expression:"user_data.password"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"confirm_ password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-password-input',{attrs:{"label":"Confirm Password","placeholder":"Confirm password","errors":errors.concat( _vm.passwordError)},on:{"input":_vm.sameAs},model:{value:(_vm.user_data.confirm_password),callback:function ($$v) {_vm.$set(_vm.user_data, "confirm_password", $$v)},expression:"user_data.confirm_password"}})]}}])})],1)]),_c('div',{staticClass:"row my-1"},[_c('div',{staticClass:"col-12"},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"d-flex align-items-center cursor-pointer",attrs:{"name":"checkbox-1"},model:{value:(_vm.user_data.accept_terms),callback:function ($$v) {_vm.$set(_vm.user_data, "accept_terms", $$v)},expression:"user_data.accept_terms"}},[_c('app-normal-text',[_vm._v(" I accept all the "),_c('b-link',[_vm._v("Terms & Conditions")]),_vm._v(" and I have read "),_c('b-link',[_vm._v("Privacy Policy")])],1)],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('app-simple-button',{staticClass:"mb-16",attrs:{"variant":"primary","loading":_vm.loading,"disabled":!_vm.user_data.accept_terms},on:{"click":_vm.userSignup}},[_vm._v("Next")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }