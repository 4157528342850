<template>
    <layout-auth>
      <div class="px-138">
        <div class="my-96">
          <img src="@/assets/images/logo/dashboard-logo.svg" alt="">
        </div>
        <app-heading-1 class="">Welcome to Collabdocs</app-heading-1>
        <app-normal-text class="mb-2">Please update following information before proceeding next</app-normal-text>
        <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
        <validation-observer ref="registerForm" >
          <div class="row">
            <div class="col-6">
              <validation-provider #default="{ errors }" name="First name" vid="first_name" rules="required|name">
                <app-text-input label="First Name" v-model="user_data.first_name" placeholder="Jhon" :errors="errors" @onKeydown="isLetter"/> 
              </validation-provider>
            </div>
            <div class="col-6">
              <validation-provider #default="{ errors }" name="Last name" vid="last_name" rules="required|name">
                <app-text-input label="Last Name" v-model="user_data.last_name" placeholder="Doe" :errors="errors" @onKeydown="isLetter"/> 
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <validation-provider #default="{ errors }" name="Phone number" vid="phone_number" rules="required|phoneno">
                <app-text-input label="Phone Number" v-model="user_data.phone_number" placeholder="xxx xxx xxxx" :errors="errors" @onKeydown="isPhoneNumber"/> 
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <validation-provider #default="{ errors }" name="Password" vid="password" rules="required|validPassword">
                <app-password-input label="Password" v-model="user_data.password" placeholder="Enter password" :errors="errors"></app-password-input>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <validation-provider #default="{ errors }" name="Confirm Password" vid="confirm_ password" rules="required">
                <app-password-input label="Confirm Password" v-model="user_data.confirm_password" @input="sameAs" placeholder="Confirm password" :errors="[...errors, ...passwordError]"></app-password-input>
              </validation-provider>
            </div>
          </div>                    
          <div class="row my-1">
            <div class="col-12">
              <b-form-group >
                <b-form-checkbox
                  v-model="user_data.accept_terms"
                  name="checkbox-1"
                  class="d-flex align-items-center cursor-pointer"
                >
                <app-normal-text>
                  I accept all the <b-link>Terms & Conditions</b-link> and I have read <b-link>Privacy Policy</b-link>
                </app-normal-text>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
                <app-simple-button variant="primary" :loading="loading" :disabled="!user_data.accept_terms" class="mb-16" @click="userSignup">Next</app-simple-button>
                <!-- <app-normal-text>
                  Already have an account? <b-link to="/login">Sign In</b-link>
                </app-normal-text> -->
            </div>
          </div>
        </validation-observer>
      </div>
    </layout-auth>
  </template>
  
  <script>
  import LayoutAuth from '@/layouts/layout-auth.vue'
  import {
    BLink,BFormCheckbox, BFormGroup, BAlert
  } from 'bootstrap-vue'
  import AppHeading1 from '@/components/app-components/AppHeading1.vue'
  import AppNormalText from '@/components/app-components/AppNormalText.vue'
  import AppTextInput from '@/components/app-components/AppTextInput.vue'
  import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
  import AppNameInput from '@/components/app-components/AppNameInput.vue'
  import AppPhoneInput from '@/components/app-components/AppPhoneInput.vue'
  import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
  import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email } from '@validations'
  import {signup_via_invite} from '@/apis/auth'
  extend('phoneno', value => {
      var regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (regex.test(value)){
        return true
      }else{
        return 'Please enter a valid phone numeber (e.g.223....)'
      }  
    } 
  )
  extend('name', value => {
      var regex = /^[A-Za-z]+$/;
      if (regex.test(value)){
        return true
      }else{
        return 'Please enter valid name'
      }  
    } 
  )
  extend('validPassword', value=>{
      var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
      if(regex.test(value)){
        return true
      }else{
        return 'Password should be 8 characters long and contain atleast one capital letter and special character'
      }
    })
  

  export default {
    components: {
      LayoutAuth,
      BLink,
      BFormCheckbox,
      BFormGroup,
      BAlert,
      AppHeading1,
      AppNormalText,
      AppTextInput,
      AppPasswordInput, 
      AppSelectBox,
      AppSimpleButton,
      ValidationProvider, 
      ValidationObserver,
      AppNameInput,
      AppPhoneInput
    },
    data() {
      return {
        options: [
          { value: null, text: 'Please select your role' },
          { value: 'client', text: 'Client' },
          { value: 'vendor', text: 'Vendor' },
          { value: 'service_provider', text: 'Service Provider' },
          { value: 'broker', text: 'Broker'},
        ],
        user_data:{
          first_name:'',
          last_name:'',
          phone_number:'',
          password:'',
          confirm_password:'',
          phone_number:'',
          accept_terms:false
        },
        passwordError:[],
        loading:false,
        required, 
        email,
        errorMessage:''       
      }
    },
    methods: {
      userSignup() {
        this.errorMessage='';
        this.$refs.registerForm.validate().then(success=>{
          if(!success || this.passwordError.length) {
            window.scrollTo(0,0);
            this.errorMessage = 'Please fill all the required fields & enter valid data'
            return
          }
          this.loading=true
            let data={
                email:this.$route.params.email,
                token:this.$route.params.token,
                ...this.user_data
            }
            signup_via_invite(data).then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Info Updated',
                    variant: 'success',
                    solid: true,
                })
                localStorage.setItem('accessToken', resp.data.accessToken);
                localStorage.setItem('refreshToken', resp.data.refreshToken);
                let userData={...resp.data.user,role:resp.data.user.roles[0]}
                localStorage.setItem('user', JSON.stringify(userData));
                setTimeout(()=>{this.$router.replace('/')},[1000])
            }).catch(error=>{
                this.loading = false
                this.errorMessage = error.message;
            })
        })
        
        // this.$router.push({path:'./account-verification', query:{email:'test@gmail.com'}})
      },
      sameAs(){
        if(this.user_data.password==this.user_data.confirm_password){
          this.passwordError = []
        }else{
          this.passwordError = ['Passwords do not match']
        }
      },
      isLetter(e) {
        if(![8,9,37,39,46].includes(e.keyCode)){
          let char = String.fromCharCode(e.keyCode); // Get the character
          if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
          else e.preventDefault(); // If not match, don't add to input text
        }
        
      },
      isPhoneNumber(e){
        if(![8,9,37,39,46].includes(e.keyCode)){
          let char = String.fromCharCode(e.keyCode); // Get the character
          if(/[0-9]/.test(char) && this.user_data.phone_number.length<10) return true;
          // if(this.user_data.phone_number.length<11) return true; // Match with regex 
          else e.preventDefault(); 
        }
      }
    },
  }
  /* eslint-disable global-require */
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  